@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: local('Montserrat'),
    url(./fonts/Montserrat/Montserrat-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'),
    url(./fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'),
    url(./fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: local('Montserrat'),
    url(./fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}
