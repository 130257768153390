$selectedTheme: 'default';

$colors: (
  // DEFAULT THEME
  'defaultBackground': #f0f0f5,
  'defaultBackgroundDarker': #f2f4f6,
  'defaultOutline': #dbdfe2,
  'defaultAccent1': #ffca1c,
  'defaultAccent2': #1a74bb,
  'defaultAccent3': #004288,
  'defaultAccent4': #322f20,
  'defaultAccent5': #ff1c1c,
  'defaultSecondaryDark': #1a74bb,
  'defaultSecondaryDarker': #1a74bb,
  'defaultText': #282233,
  'defaultPrimary': #1a74bb,
  'defaultLight': #ffffff,
  'defaultAccent': #1a74bb,
  'defaultGradientBackground': #edeef4,
  'defaultMainBackground': #c7d8e5,
  'defaultLightBackground': #f5f0ee,
  'defaultPrimaryDark': #482fb4,
  // DARK THEME
  'darkBackground': #000000,
  'darkBackgroundDarker': #000000,
  'darkOutline': #44444f,
  'darkAccent1': #50b5ff,
  'darkAccent2': #ff9ad5,
  'darkAccent3': #3dd598,
  'darkAccent4': #ffc542,
  'darkAccent5': #fc5a5a,
  'darkSecondaryDark': #92929d,
  'darkSecondaryDarker': #778ca2,
  'darkText': #fafafb,
  'darkPrimary': #1a74bb,
  'darkLight': #1c1c24,
  'darkAccent': #1a74bb,
  'darkGradientBackground': #000000,
  'darkMainBackground': #246ace
);

@function getColor($key) {
  @return map-get($colors, $key);
}

$theme-props: (
  'background',
  'backgroundDarker',
  'primary',
  'outline',
  'accent1',
  'accent2',
  'accent3',
  'accent4',
  'accent5',
  'secondaryDark',
  'secondaryDarker',
  'text',
  'light',
  'accent',
  'gradientBackground',
  'mainBackground',
  'lightBackground',
  'primaryDark'
);

@function getThemeProps($themeName) {
  $selectedTheme: $themeName;
  $colors: ();

  @each $key in $theme-props {
    $firstLetter: to_upper_case(str_slice($key, 0, 1));
    $otherLetters: str_slice($key, 2, str_length($key));
    $colors: map-merge(
      $colors,
      (
        $key: getColor(#{$themeName}#{$firstLetter}#{$otherLetters}),
      )
    );
  }
  @return $colors;
}
@import '~@mobiscroll/react/dist/css/mobiscroll';
@include mbsc-custom-theme('default', 'ios', (getThemeProps('default')));
@include mbsc-custom-theme('dark', 'ios', (getThemeProps('dark')));

//@include mbsc-custom-theme("funk", "ios", (getThemeProps("funk")));
//@include mbsc-custom-theme("swiss", "ios", (getThemeProps("swiss")));
//@include mbsc-custom-theme("base", "ios", (getThemeProps("base")));
//@include mbsc-custom-theme("light", "ios", (getThemeProps("light")));

:export {
  @each $key, $value in $colors {
    #{unquote($key)}: $value;
  }
}
